import React from "react"
import { isInFuture } from "../../utils/dates"
import NewsArticle from "./NewsArticle"

const NewsList = ({ newsArticles, selectedFilter }) => (
  <div>
    {newsArticles.map((article, index) => {
      if (!isInFuture(article.publish_date, article.publish_time) &&
        (!article.filters ||
          selectedFilter === 'All' ||
          (article.filters && article.filters.find(f => f.label === selectedFilter)))) {
        return (
          <NewsArticle key={article.id} newsArticle={article} last={index === newsArticles.length - 1} />
        )
      }
      return null
    })}
  </div>
)

export default NewsList
