import React from "react"
import DateDisplay from "../DateDisplay"
import Text from "../Text"
import YouTubeVideo from "../YouTubeVideo"
import './styles.scss'

const NewsArticle = ({ newsArticle, last }) => (
  <div className={`news-article ${last ? 'mb-0' : ''}`}>
    <Text.Header>{newsArticle.headline}</Text.Header>
    <div className="pl-md-3 mt-4">
      <DateDisplay dateStr={newsArticle.publish_date} />
      {
        newsArticle.image &&
        <img src={newsArticle.image} alt={newsArticle.headline} />
      }
      {
        newsArticle.video && newsArticle.video.video_url &&
        <YouTubeVideo watchURL={newsArticle.video.video_url} title={newsArticle.video.title} />
      }
      <Text.Normal className="mt-4">
        <div dangerouslySetInnerHTML={{ __html: newsArticle.content }}></div>
      </Text.Normal>
    </div>
  </div>
)

export default NewsArticle
