import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import NewsList from "../components/NewsList"
import FiltersHeader from "../components/FiltersHeader"

const NewsPage = ({ data }) => {
  const [filters, setFilters] = useState([])
  const [activeFilter, setActiveFilter] = useState({ value: 'All' })
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    const getFilters = () => {
      let filters = []
      data.allNewsArticles.nodes.forEach(d => {
        d.filters.forEach(f => {
          if (filters.indexOf(f.label) === -1) {
            filters.push(f.label)
          }
        })
      })
      setFilters(filters)
    }
    if (data) {
      getFilters()
    }
    setHasMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!data) return null
  if (!hasMounted) {
    return (
      <Layout contentTopMargin noBreadcrumb>
        <Seo title="News" />
      </Layout>
    )
  }
  return (
    <Layout contentTopMargin noBreadcrumb>
      <Seo title="News" />
      <FiltersHeader title="News" filters={filters} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
      <NewsList selectedFilter={activeFilter.value} newsArticles={data.allNewsArticles.nodes} />
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query {
    allNewsArticles(sort: {order: DESC, fields: publish_date}) {
      nodes {
        id
        headline
        publish_date
        publish_time
        content
        video
        image
        filters {
          label
        }
      }
    }
  }
`
